// import React from 'react';
// import HrisMain from '../../components/HrisIntegration';

// function HrisContainer() {
//   return (
//     <React.Fragment>
//       <HrisMain/>
//     </React.Fragment>
//   )
// }

// export default HrisContainer

/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HrisMain from '../../components/HrisIntegration';

class HrisContainer extends Component {
  render() {
    const { history, companyInfo} = this.props;
    return (
      <React.Fragment>
        <HrisMain companyInfo={companyInfo} history={history}/>
      </React.Fragment>
    )
  }
}

HrisContainer.propTypes = {
  history: PropTypes.object.isRequired,
  companyInfo: PropTypes.object
};

export default connect(null, null)(HrisContainer);

