/* eslint-disable no-console,no-undef */
import React,{ Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAdminUser, getUserRole, getCompany} from '../../redux/actions';
import {
  Leaderboard,
  NotFound,
  Waiting
} from '../../components';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import {setCompany} from '../../redux/actions/leaderboardActions'
class LeaderboardContainer extends Component {

  componentDidMount() {
    const { getAdminUser, fetchUserRole, fetchCompany, userCompany, setCompany } = this.props;
    const startYear = userCompany.companyStartDate && userCompany.companyStartDate["company_start_date"];
    getAdminUser();
    fetchUserRole();
    setCompany(userCompany.id, userCompany.name, startYear, userCompany.showBiometricStatus);
    fetchCompany()
  }

  leaderboard = () =>{
    const { history, companyInfo } = this.props;
    return(
      <Leaderboard
        history={history}
        companyInfo={companyInfo}
      />
    )
  }

  render() {
    const { companyInfo } = this.props;
    if(isNull(companyInfo) || isUndefined(companyInfo)){
      return <Waiting />
    }
    return (
      <Switch>
        <Route
          render={this.leaderboard}
        />
        <Route component={NotFound}/>
      </Switch>
    )
  }
}

LeaderboardContainer.defaultProps = {
  companyInfo: {id: 1, name: '', companyStartDate: 2016}
};
LeaderboardContainer.propTypes = {
  history: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  getAdminUser: PropTypes.func.isRequired,
  fetchUserRole: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  user: PropTypes.object,
  userPermissions: PropTypes.array,
  userCompany: PropTypes.object,
  companyInfo: PropTypes.object.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  setCompany: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  role: state.auth.role,
  userPermissions: state.profileData.userPermissions,
  companyInfo: state.companyDashboard.companyInfo
});

const mapDispatchToProps = (dispatch) => ({
  getAdminUser: () => dispatch(getAdminUser()),
  fetchUserRole: () => dispatch(getUserRole()),
  fetchCompany: () => dispatch(getCompany()),
  setCompany: (id, name, companyStartDate, biometricStatus) => dispatch(setCompany(id, name, companyStartDate, biometricStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardContainer);
