import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { HowItWorksDashBoard } from '../../components';

class HowItWorks extends Component {
  render() {
    const { history } = this.props;
    return (
      <HowItWorksDashBoard  history={history}/>
    )
  }
}

HowItWorks.propTypes = {
  history: PropTypes.object
}

export default HowItWorks;